body {
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  /* Dark gray for better readability */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-bottom: 0.5em;
  color: #222;
  /* Slightly darker for headings */
}

h1 {
  font-size: 32px;
  /* Primary Heading */
}

h2 {
  font-size: 28px;
  /* Secondary Heading */
}

h3 {
  font-size: 24px;
  /* Tertiary Heading */
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p,
li {
  font-weight: 400;
  margin-bottom: 1em;
}

.small-text {
  font-size: 14px;
  color: #666;
  /* Lighter gray for less important text */
}

.button {
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

.navbar,
.sidebar {
  font-size: 15px;
  font-weight: 500;
  background-color: #f7f7f7;
  /* Light background for contrast */
}

.table {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.table th {
  font-weight: 600;
  background-color: #f2f2f2;
  /* Light gray background for table headers */
}

.table td {
  padding: 10px;
}

.footer {
  font-size: 14px;
  font-weight: 300;
  color: #999;
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
}